import Swiper from 'swiper/dist/js/swiper.js';
import AOS from 'aos';
import Blazy from 'blazy';
import cookieBar from './cookie/jquery.cookiebar.js';
import jqueryui from './jquery-ui/jquery-ui.min.js';
import photobox from './photobox/photobox/jquery.photobox.js';
import datepicker_ita from './lingue_calendario/jquery.ui.datepicker-ita.min.js';
import datepicker_eng from './lingue_calendario/jquery.ui.datepicker-eng.min.js';
import datepicker_fra from './lingue_calendario/jquery.ui.datepicker-fra.min.js';
import datepicker_deu from './lingue_calendario/jquery.ui.datepicker-deu.min.js';

jQuery(document).ready(function( $ ) {
  var mySwiperTop = new Swiper ('.gallery_top', {
        direction: 'horizontal',
        effect: 'fade',
        autoplay:2000,
        speed: 1500,
        autoplayDisableOnInteraction: true,
        loop: true,
        pagination: '.gallery_top .swiper-pagination',
        paginationClickable: true,
        nextButton:  '.gallery_top .swiper-button-next',
        prevButton: '.gallery_top .swiper-button-prev'
    });


    var mySwiperOfSlide = new Swiper ('.off-slide', {
         direction: 'horizontal',
         speed: 1300,
         spaceBetween: 2,
         delay: 800,
        autoplayDisableOnInteraction: true,
         effect: 'fade',
         autoplay:2000,
         loop: true,
         slidesPerView: 1,
         //lazyLoading: true,
         //preloadImages:false
        });

        var mySwiperOfSlide2 = new Swiper ('.off-slide2', {
             direction: 'horizontal',
             speed: 1300,
             spaceBetween: 2,
             delay: 800,
            autoplayDisableOnInteraction: true,
             effect: 'fade',
             autoplay:2000,
             loop: true,
             slidesPerView: 1,

            });

            /*aggiungo classe al li se ha menu secondario*/
          $('.menu-top ul li .sub-menu').parent('li').addClass('dropdown');

          $( ".menu-top ul li" ).each(function(){
          		if($( this ).hasClass( "dropdown" )){
          			$( this ).append('<div class=\"cont-fr\"><i class=\"fal fa-chevron-down\"></i></div>');
          		}
          	});


            /*al click della freccia apro quello cliccato e chiudo gli altri*/
            $( ".cont-fr" ).each(function(){
            $(this).click(function(){
                if($(this).hasClass('rotate')){
                  $(this).removeClass('rotate');
                }else{
                  $( ".cont-fr" ).removeClass('rotate');
                  $(this).addClass('rotate');
                }
              if($(this).parent().find('ul').hasClass("open")){
                $(this).parent().find('ul').removeClass( "open" );
              }else{
                $( ".menu-top ul li ul" ).removeClass( "open" );
                  $(this).parent().find('ul').addClass( "open" );
              }
              });
            });

        $( ".cont-hamb").click(function() {
          $('.cont-menu').toggleClass('open');
          $('.cont-hamb .fa-bars').toggleClass('hide');
          $('.cont-hamb .fa-times').toggleClass('show');
          $('h1').toggleClass('opa');
          $('.logo').toggleClass('hide');
          $('.cont_show').toggleClass('hide');
        });

        $(".cont_show").click(function() {
          $('html, body').animate({
            //scrollTop: ($(".cont-qr .title").offset().top-100)
            scrollTop: ($(".gallery_top").height()-$(".over-top").height())
          }, 1500);
        });

        $(".lingue li:last-child").click(function() {
          $('html, body').animate({
            scrollTop: ($(".cont-qr .title").offset().top-100)
          }, 1500);
        });

         $('body').photobox('.photobox');

        var offset = 150;
        var $back_to_top = $('.cd-top');
       $(window).scroll(function(){
           if( $(this).scrollTop() > offset ){
             $back_to_top.addClass('cd-is-visible');
             $('body').addClass('scroll');
           } else {
             $back_to_top.removeClass('cd-is-visible cd-fade-out');
             $('body').removeClass('scroll');
           }
       });

        $('.filter').on( "click", function() {
        var filterValue = $(this).attr('data-filter');
        $(".filter").removeClass('active');
        $(this).addClass('active');
        $( ".gallery_isotope li" ).removeClass('hide');

        $( ".gallery_isotope li" ).each(function() {
              var filterClass =  $(this).attr('class');
                if(filterClass != filterValue) $(this).addClass('hide');
                if(filterValue == '*') $( ".gallery_isotope li" ).removeClass('hide');
            });
        });

        var bLazy = new Blazy({});

        var lang =  $('#lang').val();
        $.datepicker.setDefaults($.datepicker.regional[lang]);

        /******MODIFICA QR DATA APERTURA*******/
        var v_mindate = new Date('2021, 4, 24');
        var v_mindate_p = new Date('2021, 4, 25');
        var oggi = new Date();
        var diff = v_mindate.getTime() - oggi.getTime();

          if(diff<=0){
              v_mindate = oggi;
              v_mindate_p = new Date();
              v_mindate_p.setDate(oggi.getDate() + 1); 
          } else {
             v_mindate_p.setDate(v_mindate_p.getDate());
          }

          // var v_maxdate = v_mindate;
          // v_maxdate.setDate(v_mindate.getDate() + 1);

        /******MODIFICA QR DATA APERTURA*******/

        $( "#datepicker" ).datepicker({
           dateFormat:"dd/mm/yy",
           buttonImageOnly: true,
           altField: "#alternate",
           altFormat: "dd-mm-yy",
           minDate: v_mindate, //new Date(),

           onSelect: function(dateText){

             var date = $("#datepicker").datepicker("getDate");

             $(".arrivo .gg").html($.datepicker.formatDate("dd", date));
             $(".arrivo .mm").html($.datepicker.formatDate("M", date));
             $(".arrivo .aa").html($.datepicker.formatDate("y", date));


             if($('#datepicker').datepicker('getDate') >= $('#datepicker_partenza').datepicker('getDate')){

               var nextDayDate = $('#datepicker').datepicker('getDate', '+1d');
               nextDayDate.setDate(nextDayDate.getDate());

               var nextDayDatep1 = $('#datepicker').datepicker('getDate', '+1d');
               nextDayDatep1.setDate(nextDayDatep1.getDate() + 1);

               $("#datepicker_partenza").datepicker( "option", "minDate", nextDayDatep1);
               $('#datepicker_partenza').datepicker('setDate', nextDayDate);

               var date_1 = $("#datepicker_partenza").datepicker("getDate");
               $(".partenza .gg").html($.datepicker.formatDate("dd", date_1));
               $(".partenza .mm").html($.datepicker.formatDate("M", date_1));
               $(".partenza .aa").html($.datepicker.formatDate("y", date_1));

             } else {
               var nextDayDate = $('#datepicker').datepicker('getDate', '+1d');
               nextDayDate.setDate(nextDayDate.getDate() + 1);
               $("#datepicker_partenza").datepicker( "option", "minDate", nextDayDate);
             }

              prenota();
           }
         }).datepicker( "setDate", "d" );

         $("#datepicker_partenza").datepicker({
           dateFormat:"dd/mm/yy",
           buttonImageOnly: true,
           altField: "#alternate_partenza",
           altFormat: "dd-mm-yy",
           minDate: v_mindate_p, //new Date(),

           onSelect: function(dateText) {
             var date = $("#datepicker_partenza").datepicker("getDate");
             $(".partenza .gg").html($.datepicker.formatDate("dd", date));
             $(".partenza .mm").html($.datepicker.formatDate("M", date));
             $(".partenza .aa").html($.datepicker.formatDate("y", date));

                prenota();
           }

         }).datepicker( "setDate", v_mindate_p );

         //var openPiu = $("#datepicker_partenza").datepicker("getDate");
         //$("#datepicker_partenza").datepicker("setDate", openPiu);

         //.datepicker( "setDate", "+1d" );







         var date = $("#datepicker").datepicker("getDate");
         var date_1 = $("#datepicker_partenza").datepicker("getDate");

         $(".arrivo .gg").html($.datepicker.formatDate("dd", date));
         $(".arrivo .mm").html($.datepicker.formatDate("M", date));
         $(".arrivo .aa").html($.datepicker.formatDate("y", date));

         $(".partenza .gg").html($.datepicker.formatDate("dd", date_1));
         $(".partenza .mm").html($.datepicker.formatDate("M", date_1));
         $(".partenza .aa").html($.datepicker.formatDate("y", date_1));
              prenota();

        if($('#ancora').val() == 'location'){
          initMap();
        }
});


function initMap() {
  var lanthia = {lat: 39.986354, lng: 9.686506};
  var map = new google.maps.Map(
      document.getElementById('map'), {
        zoom: 16,
        center: lanthia,
        mapTypeId: 'satellite',
        styles: [
          {
             featureType: 'poi.business',
             stylers: [{visibility: 'off'}]
           }
        ]
      }
    );
  var contentString = '<img src="https://www.lanthiaresort.com/wp-content/themes/lanthiaresort/modules/public/images/lant.jpg"><div class="textmap">08040 Santa Maria Navarrese Sardegna - Italia<br />e-mail: info@lanthiaresort.com<br />tel +39.0782.615103<br />info@lanthiaresort.com</div>';
  var infowindow = new google.maps.InfoWindow({
     content: contentString
   });

  var marker = new google.maps.Marker({position: lanthia, map: map, title:"Lanthia Resort"});

  marker.addListener('click', function() {
  infowindow.open(map, marker);
});



}



  function prenota(){
    var split_arrivo = $("#datepicker").val();

     split_arrivo = split_arrivo.split('/');
     var aa_arrivo = split_arrivo[2];
     var mm_arrivo = split_arrivo[1];
     var gg_arrivo = split_arrivo[0];

     $('[name=aa]').val(aa_arrivo);
     $('[name=mm]').val(mm_arrivo);
     $('[name=gg]').val(gg_arrivo);


     var split_partenza = $("#datepicker_partenza").val();
      split_partenza = split_partenza.split('/');
      var aa_partenza = split_partenza[2];
      var mm_partenza = split_partenza[1];
      var gg_partenza = split_partenza[0];


    var fromdate = new Date( aa_arrivo, mm_arrivo-1, gg_arrivo);

     var todate = new Date( aa_partenza, mm_partenza-1, gg_partenza);
     var giorni_differenza = (todate-fromdate)/86400000;
     giorni_differenza = Math.round(giorni_differenza);

     $('[name=notti_1]').val(giorni_differenza);
  }

function video(video){
    $( "body" ).append( "<div class=\"cont_video\"><i class=\"fa fa-window-close\" aria-hidden=\"true\"></i><video controls=\"controls\" width=\"auto\" height=\"50%\" autoplay><source src=\""+video+"\" type=\"video/mp4\" />Your browser does not support the video tag.</video></div>" );

    $(".fa-window-close").click(function(){
      $(".cont_video").remove();
    });
}

function cookie(){

switch($('#lingua').val()){
  case 'ita':
    var testo = 'Questo sito utilizza cookie tecnici e di profilazione di terze parti. Per ulteriori informazioni o per negare il consenso, leggi la informativa estesa. Proseguendo con la navigazione acconsenti a usare i cookie.';
      var label = 'Accetta e chiudi';
  break;

  default:
    var testo = 'This site uses technical cookies and third party profiling. To learn more or opt out, read the complete cookie policy statement. By continuing to use this site, we assume you are happy with it';

    var label = 'Accept and close';
  break;
}
  $.cookieBar({
    message: testo,
    acceptText: label,
    policyButton: true,
    policyText: 'Privacy Policy',
    policyURL: $('#linkcookie').val(),
    expireDays: 30
  });
}
